import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../icon'

import './index.css'

const OutNewsCard = ({ url, title, image, style, children }) => (
  <a
    href={url}
    target="_blank"
    rel="noopener noreferrer nofollow"
    className="OutNewsCard ta-center ta-lg-left py-60"
    style={style}
  >
    <div className="row align-items-center">
      <div className="col-12 col-lg-3">{image}</div>
      <div className="col-12 col-lg-7 mt-24 mt-lg-0">
        <h5 className="OutNewsCard-title">{title}</h5>
        <div className="mt-16">{children}</div>
      </div>
      <div className="col-12 col-lg-2 d-none d-lg-block ta-lg-right">
        <Icon className="OutNewsCard-arrow" />
      </div>
    </div>
  </a>
)

OutNewsCard.defaultProps = {}

OutNewsCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.node,
  children: PropTypes.node
}

export default OutNewsCard
