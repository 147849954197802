import React from 'react'
import PropTypes from 'prop-types'

import FlexibleLink from '@c/common/FlexibleLink'

import RedLine from '../red-line'
import Icon from '../icon'

import './index.css'

const NewsCard = ({ url, title, image, children }) => (
  <FlexibleLink
    url={url}
    target="_blank"
    className="NewsCard mb-60 mb-lg-0"
    title={
      <>
        {image}
        <h4 className="mt-24 NewsCard-title">{title}</h4>
        <RedLine />
        {children && <div className="mt-8">{children}</div>}
        <div className="d-none d-lg-block mt-24">
          <Icon className="NewsCard-arrow" />
        </div>
      </>
    }
  />
)

NewsCard.defaultProps = {}

NewsCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.node,
  children: PropTypes.node
}

export default NewsCard
