import React from 'react'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'

import Hero from '@v/we-in-the-press/hero'
import News from '@v/we-in-the-press/news'
import DatoCMSModel from '../models/DatoCMSModel'

const Page = ({ data }) => {
  const { page } = data
  const { title } = page
  const model = new DatoCMSModel(page)
  const newsAndLocale = page.news.find(
    newsByLocale => newsByLocale.locale === page.locale
  ) || { locale: page.locale, value: [] }
  const news = newsAndLocale.value
  const allFeaturedNews = news.filter(update => update.featured)
  const featured = allFeaturedNews.slice(0, 2) // first two featured news
  const otherNews = news
    .filter(
      update =>
        !featured.find(featuredUpdate => update.id === featuredUpdate.id)
    )
    .sort((a, b) => new Date(a.meta.createdAt) - new Date(b.meta.createdAt))

  return (
    <Layout locale={page.locale} seo={{ ...model.getSeoComponentProps() }}>
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* header*/}
      <div className="container ta-center">
        <PageHeader topTitle={page.sloganSmall} title={page.sloganBig} />
        <div className="row mt-32">
          <div className="col-12 col-lg-6 offset-lg-3">
            <p>{page.descriptionSmall}</p>
          </div>
        </div>
      </div>

      <Hero news={featured} />
      <News
        newsTitleSmall={page.newsTitleSmall}
        newsTitleBig={page.newsTitleBig}
        news={otherNews}
        loadMoreButtonText={page.loadMoreButtonText}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query WeInThePressPageQuery($locale: String) {
    page: datoCmsWeInThePress(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      locale
      title
      sloganSmall
      sloganBig
      descriptionSmall
      newsTitleSmall
      newsTitleBig
      loadMoreButtonText
      metaTags {
        ...MetaTags
      }
      news: _allNewsLocales {
        locale
        value {
          id
          title
          description
          image {
            alt
            asset {
              localImage {
                extension
                publicURL
              }
            }
          }

          featuredImage {
            alt
            asset {
              localImage {
                extension
                publicURL
              }
            }
          }

          featured
          url
          meta {
            createdAt
          }
        }
      }
    }
  }
`
