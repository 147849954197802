import React from 'react'

import NewsCard from '@c/news-card'
import ProjectImage from '@/components/project-image'

const Hero = ({ news }) => {
  return (
    <div className="container mt-60 mt-lg-80">
      <div className="row align-items-end">
        {news.map(update => (
          <div key={update.id} className="col-12 col-lg-6">
            <NewsCard
              title={update.title}
              url={update.url}
              image={<ProjectImage image={update.featuredImage} />}
            >
              <p>{update.description}</p>
            </NewsCard>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Hero
