import React from 'react'
import PageHeader from '@c/page-header'

import OutNewsCard from '@c/out-news-card'
import ProjectImage from '@/components/project-image'

const News = ({ newsTitleSmall, newsTitleBig, news }) => {
  return (
    <div className="container mt-60 mt-lg-80">
      {/* header */}
      <div className="row">
        <div className="col-12 ta-center">
          <PageHeader
            topTitle={newsTitleSmall}
            title={newsTitleBig}
            type="h3"
          />
        </div>
      </div>

      {/* cards */}
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-1 mt-24">
          {news.map(update => (
            <OutNewsCard
              key={update.id}
              title={update.title}
              url={update.url}
              image={<ProjectImage image={update.image} />}
            >
              <p>{update.description}</p>
            </OutNewsCard>
          ))}
        </div>
      </div>
    </div>
  )
}

export default News
